.Login, .LoginDesktop {
  background: url('img/BG.png') no-repeat center 100% fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.Login {
  width: 100vw;
  height: 100vh;
  align-items: center;
}

.LoginDesktop {
  padding: 12vh 0 0 15vh;
  min-height: 100vh;
}

.Login-3rd-party {
  display: flex;
  flex-direction: column;
  max-height: 115px;
  justify-content: space-between;
  max-width: 291px;
}

.Login-confirmation, .Login-confirmation-desktop {
  align-content: center;
  display: flex;
  justify-content: space-between;
  margin: 3vh;
  text-align: center;
}

.Login-confirmation {
  flex-direction: column;
  height: 120px;
  padding: 2vh;
}

.Login-confirmation-desktop {
  flex-direction: row;
  width: 580px;
  margin: 4vh 0 7vh 0;
}

.Login-header {
  max-height: 62px;
  margin-bottom: 8vh;
  max-width: 460px;
  margin-top: 10vh;
}

.Login-form {
  display: flex;
  flex-direction: column;
  height: 125px;
  justify-content: space-between;
  max-width: 580px;
  min-width: 300px;
}

.Login-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5vh;
  max-width: 240px;
  min-width: 200px;
}
