@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rasa:wght@300;400;500;600;700&display=swap);
/** a tag / links **/
a {
  text-decoration: none;
}

Link {
  text-decoration: none;
}

.link-faded {
  color: #818181;
}

.link-underline {
  color: #373737;
  text-decoration: underline;
}

/** body **/
body, .body2, .body3, .body4 {
  margin: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body2 {
  font-size: 18px;
  font-weight: 600;
}

.body3 {
  font-size: 16px;
  font-weight: 400;
}

.body4 {
  font-size: 14px;
  font-weight: 400;
}

/** other **/
html, body {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip-content {
 background: white;
 position: relative;
 border: 1px solid #ddd;
 border-radius: 10px;
 color: black;
 padding: 15px;
 z-index: 100;
 transform: translateX(-50%) translateY(5px);
 min-width: 120px;
}

/** header **/
h1, h2, h3, h4 {
  font-family: 'Rasa', -apple-system, BlinkMacSystemFont, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 115%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
    font-size: 18px;
}

#root {
  display: flex;
  justify-content: center;
  min-height: 100%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
    border-top: 2px solid rgba(0, 0, 0, 0.4);
    border-left: 2px solid rgba(0, 0, 0, 0.4);
}
.react-resizable {
    position: relative;
}
.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}
.Login, .LoginDesktop {
  background: url(/static/media/BG.b6ead530.png) no-repeat center 100% fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.Login {
  width: 100vw;
  height: 100vh;
  align-items: center;
}

.LoginDesktop {
  padding: 12vh 0 0 15vh;
  min-height: 100vh;
}

.Login-3rd-party {
  display: flex;
  flex-direction: column;
  max-height: 115px;
  justify-content: space-between;
  max-width: 291px;
}

.Login-confirmation, .Login-confirmation-desktop {
  align-content: center;
  display: flex;
  justify-content: space-between;
  margin: 3vh;
  text-align: center;
}

.Login-confirmation {
  flex-direction: column;
  height: 120px;
  padding: 2vh;
}

.Login-confirmation-desktop {
  flex-direction: row;
  width: 580px;
  margin: 4vh 0 7vh 0;
}

.Login-header {
  max-height: 62px;
  margin-bottom: 8vh;
  max-width: 460px;
  margin-top: 10vh;
}

.Login-form {
  display: flex;
  flex-direction: column;
  height: 125px;
  justify-content: space-between;
  max-width: 580px;
  min-width: 300px;
}

.Login-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5vh;
  max-width: 240px;
  min-width: 200px;
}

